@import 'projects/library/shared-scss/qp-imports';

.qp-form-control {
  @include qp-placeholder($qima-color-neutral-smoke);
  @include qp-font(16px);
  @include qima-quick-transition(border-color, box-shadow);

  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + $qima-spacing-min);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: $qima-color-neutral-carbon;
  background-color: $qima-color-neutral-white;
  background-clip: padding-box;
  border: 1px solid $qima-color-neutral-smoke;
  border-radius: 0.25rem;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $qima-color-neutral-carbon;
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &[readonly] {
    cursor: not-allowed;
    color: $qima-color-neutral-seal;
    background-color: $qima-color-neutral-cloud;
    opacity: 1;
  }
}

.qp-form-group {
  margin-bottom: 1rem;
}

.qp-form-text {
  display: block;
  margin-top: 0.25rem;
}

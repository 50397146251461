@import 'projects/library/shared-scss/qp-imports';

.qp-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$qima-spacing-m;
  margin-left: -$qima-spacing-m;
}

/* ==============================================================
Columns
=============================================================== */

@for $i from 1 through 12 {
  .qp-col-#{$i} {
    @include qp-cols-mixin($i);
  }
}

@media (min-width: $breakpoint-sm) {
  @for $i from 1 through 12 {
    .qp-col-sm-#{$i} {
      @include qp-cols-mixin($i);
    }
  }
}

@media (min-width: $breakpoint-md) {
  @for $i from 1 through 12 {
    .qp-col-md-#{$i} {
      @include qp-cols-mixin($i);
    }
  }
}

@media (min-width: $breakpoint-lg) {
  @for $i from 1 through 12 {
    .qp-col-lg-#{$i} {
      @include qp-cols-mixin($i);
    }
  }
}

@media (min-width: $breakpoint-xl) {
  @for $i from 1 through 12 {
    .qp-col-xl-#{$i} {
      @include qp-cols-mixin($i);
    }
  }
}

/* ==============================================================
Container
=============================================================== */

$qp-container-max-width-sm: 540px;
$qp-container-max-width-md: 720px;
$qp-container-max-width-lg: 960px;
$qp-container-max-width-xl: 1140px;

.qp-container {
  @media screen and (min-width: $breakpoint-sm) {
    max-width: $qp-container-max-width-sm;
  }
  @media screen and (min-width: $breakpoint-md) {
    max-width: $qp-container-max-width-md;
  }
  @media screen and (min-width: $breakpoint-lg) {
    max-width: $qp-container-max-width-lg;
  }
  @media screen and (min-width: $breakpoint-xl) {
    max-width: $qp-container-max-width-xl;
  }

  width: 100%;
  padding: 0 $qima-spacing-m;
  margin: 0 auto;
}

.qp-container-fluid {
  width: 100%;
  flex: auto;
  padding: 0 $qima-spacing-m;
  margin: 0 auto;
}

.qp-d-flex {
  display: flex;
}

.qp-align-items-center {
  align-items: center;
}

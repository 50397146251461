@import 'projects/library/shared-scss/qp-imports';

/* ==============================================================
Bootstrap tweaks
=============================================================== */
a:not(.qima-autolink, qima-button > a, qp-button > a) {
  color: $qima-color-neutral-carbon;
  font-weight: bold;

  &:hover {
    color: $qima-color-pool-500;
    font-weight: bold;

    /* make sure browsers use the pointer cursor for anchors, even with no href */
    cursor: pointer;
  }
}

// Override ng-zero
qima-link > a.qima-link,
a.qima-autolink {
  text-decoration: underline;
}

// hr styles coming from bootstrap
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgb(0 0 0 / 10%);
}

.sunset-color {
  color: $qima-decorative-guava !important;
}

// @todo remove that wrongful override :(
// @todo refactor also all "@todo anticon-ic-add-deps ..." comments as well
.anticon-ic-add {
  :not(button > em > .icon) {
    color: $qima-color-pool-500;
  }

  :not(qp-button em .icon) {
    color: inherit !important;
  }
}

* {
  caret-color: $qima-color-pool-500;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
.ng-invalid:not(.form-control, .qp-form-control, .qp-input, .qp-input-list-container, form, .qp-text-container input) {
  border: none;
}

/* other generic styles */

.container-body {
  background-color: $qima-color-neutral-cloud;
  min-height: 100vh;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-100 {
  width: 100% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.ant-select-selection-selected-value {
  width: 100%;
}

.category-details,
.product-details-row {
  em {
    color: $qima-color-pool-500;
    font-style: normal;
  }
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgb(0 0 0 / 12.5%);
}

.hand {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;

    pre {
      background: none;
      border: 0;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }

    .popover pre {
      font-size: 10px;
    }
  }

  .toast {
    position: fixed;
    width: 100%;

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }

    &.top {
      top: 55px;
    }

    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (width >= 480px) {
  .alerts .toast {
    width: 50%;
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.clickable,
.panel-title a {
  cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.page-item.active {
  .page-link {
    z-index: 0 !important;
  }
}

/* Redefined as .body-1 (QIMA typography) */
body {
  @extend %qp-typography-body-1;

  color: $qima-color-neutral-carbon;
  background-color: $qima-color-neutral-cloud;
  scroll-behavior: smooth;
  line-height: $qp-body-line-height;
  -webkit-overflow-scrolling: touch;

  // This is to override a style coming from the ngx-qima library which set a translateZ(0)
  // to ease the scrolling on iOS but prevents AppCues modals to be displayed
  &.appcues-noscroll {
    > * {
      -webkit-transform: none;
    }
  }
}

h1,
h2,
h3,
h4,
h5 {
  color: $qima-color-neutral-carbon;
}

/* Redefined as .headline-l (QIMA typography) */
h1 {
  @extend %qp-typography-headline-l;
}

/* Redefined as .headline-l (QIMA typography) */
h2 {
  @extend %qp-typography-headline-m;
}

/* Redefined as .headline-m (QIMA typography) */
h3 {
  @extend %qp-typography-headline-s;
}

h4 {
  @extend %qp-typography-headline-xs;
}

h5 {
  margin-bottom: 0;
  @extend %qp-typography-body-1;
}

/* Redefined as .label (QIMA typography) */
label {
  @extend %qp-typography-label;
}

/* Redefined as .item--highlighted (QIMA typography) */
th {
  @extend %qp-typography-item-highlighted;
}

/* Redefined as .item (QIMA typography) */
td {
  @extend %qp-typography-item;
}

/* Redefined as .main (QIMA typography) */
.navbar-brand {
  @extend %qp-typography-main;
}

.main-container {
  display: flex;
}

@media screen and (max-width: $mobile-max-width) {
  .main-container {
    flex-direction: column;
  }
}

/* for custom scss for qp-modal overwrite ng-zorro css for cdk */
.qp-modal-shadow {
  z-index: 0 !important;
  background: $qima-color-neutral-carbon;
  opacity: 0.8 !important;
}

/* qp-modal class is kept for backward compatibility with the old qp-modal service. */
.qp-modal,
.qp-modal-center {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.qp-modal-full {
  height: 100%;
  width: 100%;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.centered {
  margin: 50px auto;
  text-align: center;
}

*:focus,
*:hover,
*:active {
  outline: none !important;
}

.no-style-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.report__body {
  margin: $qima-spacing-l;

  .report__title {
    &::before {
      padding-right: 1rem;
      counter-increment: title;
      content: counter(title);
    }
  }

  .details {
    counter-reset: sub;
  }

  .report__subtitle {
    &::before {
      padding-right: 1rem;
      counter-increment: sub;
      content: counter(title) '.' counter(sub);
    }
  }
}

.cdk-global-overlay-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.centered-block {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

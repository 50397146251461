/* stylelint-disable scss/no-duplicate-dollar-variables */
/* stylelint-disable scss/dollar-variable-empty-line-before-$-variable */
/// Slice `$list` between `$start` and `$end` indexes
///
/// @param {List} $list - List to slice
/// @param {Number} $start [1] - Start index
/// @param {Number} $end [length($list)] - End index
/// @return {List} Sliced list
@function slice($list, $start: 1, $end: length($list)) {
  @if length($list) < 1 or $start > $end {
    @return ();
  }

  $result: ();
  @for $i from $start through $end {
    $result: append($result, nth($list, $i));
  }
  @return $result;
}

@import 'projects/library/shared-scss/qp-imports';

img {
  vertical-align: middle;
  border-style: none;
}

@media print {
  img {
    page-break-inside: avoid;
  }
}

@import 'projects/library/shared-scss/qp-imports';

// Apply mobile css rules if we are under $mobile-max-width OR if we are in orientation portrait (IPad width is over $mobile-max-width)
@media screen and (max-width: $mobile-max-width), screen and (orientation: portrait) {
  .qp-button > * {
    &.type-hero-red {
      &:active {
        background-color: $qima-color-cherry-600;
      }

      &:not(:active) {
        background-color: $qima-brand-red;
      }
    }

    &.type-hero-blue {
      &:active {
        background-color: $qima-color-pool-600;
      }

      &:not(:active) {
        background-color: $qima-color-pool-500;
      }
    }

    &.type-hero-green {
      &:active {
        background-color: $qima-color-kiwi-600;
      }

      &:not(:active) {
        background-color: $qima-color-kiwi-500;
      }
    }

    &.type-hollow {
      &:active {
        background-color: $qima-color-neutral-carbon;
        color: $qima-color-neutral-white;
      }

      &:not(:active) {
        background-color: $qima-color-neutral-cloud;
        color: $qima-color-neutral-carbon;
      }
    }
  }
}

.qp-button > * {
  @include qima-quick-transition(all);

  border: 0;
  border-radius: 6px;
  height: $qp-button-size-large-height;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline-style: none;
  cursor: pointer;
  text-decoration: none !important;

  &:disabled,
  &[disabled] {
    cursor: no-drop !important;
  }

  &.type-hero-red {
    background-color: $qima-brand-red;
    color: $qima-color-neutral-white;

    &:hover,
    &:active {
      background-color: $qima-color-cherry-600;
    }

    &:disabled,
    &[disabled] {
      background-color: $qima-color-neutral-pearl;
      color: $qima-color-neutral-smoke;
    }
  }

  &.type-hero-pool {
    background-color: $qima-color-pool-500;
    color: $qima-color-neutral-white;

    &:hover,
    &:active {
      background-color: $qima-color-pool-600;
    }

    &:disabled,
    &[disabled] {
      background-color: $qima-color-neutral-pearl;
      color: $qima-color-neutral-smoke;
    }
  }

  &.type-hero-blue {
    background-color: $qima-color-pool-500;
    color: $qima-color-neutral-white !important;

    &:hover,
    &:active {
      background-color: $qima-color-pool-600;
    }

    &:disabled,
    &[disabled] {
      background-color: $qima-color-neutral-pearl;
      color: $qima-color-neutral-smoke;
    }
  }

  &.type-hero-green {
    background-color: $qima-color-kiwi-500;
    color: $qima-color-neutral-white;

    &:hover,
    &:active {
      background-color: $qima-color-kiwi-600;
    }

    &:disabled,
    &[disabled] {
      background-color: $qima-color-neutral-pearl;
      color: $qima-color-neutral-smoke;
    }
  }

  &.type-simple {
    background-color: transparent;
    border: none;
    color: $qima-color-neutral-carbon;

    &:hover,
    &:active {
      color: $qima-color-pool-600;
    }

    &[disabled] {
      color: $qima-color-neutral-smoke;

      em {
        color: $qima-color-neutral-smoke;
      }
    }

    em {
      color: $qima-color-pool-600;
    }
  }

  &.type-hollow {
    background-color: transparent;
    color: $qima-color-neutral-carbon;
    border: solid 1px $qima-color-neutral-carbon;

    &:hover,
    &:active {
      border-color: $qima-color-neutral-seal;
      color: $qima-color-neutral-seal;
    }

    &:disabled,
    &[disabled] {
      background-color: transparent;
      color: $qima-color-neutral-smoke;
      border: solid 1px $qima-color-neutral-smoke;
    }
  }

  &.size-small {
    height: $qp-button-size-small-height;
    padding-left: $qima-spacing-s;
    padding-right: $qima-spacing-s;
    @extend %qp-typography-interactive-2;
  }

  &.size-medium {
    height: $qp-button-size-medium-height;
    padding-left: $qima-spacing-m;
    padding-right: $qima-spacing-m;
    @extend %qp-typography-interactive-1;
  }

  &.size-large {
    padding-left: $qima-spacing-m;
    padding-right: $qima-spacing-m;
    @extend %qp-typography-interactive-1;

    .button-icon {
      margin-right: 6px;
      font-size: 20px;
    }
  }

  &.width-full {
    min-width: 260px;
  }

  &.width-large {
    min-width: 156px;
  }

  &.width-medium {
    min-width: 96px;
  }

  &.width-small {
    min-width: 60px;
  }

  .button-icon {
    margin-right: 6px;
    font-size: 20px;
  }
}

/**
 * @IMPORTANT
 * Colors defined here are also defined in an enum inside $qima-color.enum.ts file
 * Each color change has to be applied to both files
 */
$qima-brand-red: #E4002B;
$qima-decorative-aubergine: #524DB1;
$qima-decorative-avocado: #57C49F;
$qima-decorative-beetroot: #741448;
$qima-decorative-blueberry: #0070D7;
$qima-decorative-cabbage: #A086C4;
$qima-decorative-elderberry: #5E90CC;
$qima-decorative-forest: #29A37E;
$qima-decorative-grapes: #A2005D;
$qima-decorative-guava: #FF6159;
$qima-decorative-mandarin: #FFBE0F;
$qima-decorative-melon: #F7A963;
$qima-decorative-olive: #00AEB7;
$qima-decorative-peach: #FF8261;
$qima-decorative-plum: #170F78;
$qima-decorative-pumpkin: #D97F2F;
$qima-decorative-waterlily: #72D0E3;
$qima-color-pool-050: #F2F5FD;
$qima-color-pool-100: #E6ECFB;
$qima-color-pool-200: #D3DCF8;
$qima-color-pool-500: #4169E1;
$qima-color-pool-600: #1E46BD;
$qima-color-pool-900: #153184;
$qima-color-orange-100: #FCF2E6;
$qima-color-orange-200: #F9E4CD;
$qima-color-orange-500: #F58F00;
$qima-color-orange-600: #D67D00;
$qima-color-orange-900: #B26309;
$qima-color-cherry-100: #FDEFEF;
$qima-color-cherry-200: #FBDDDD;
$qima-color-cherry-500: #E5353D;
$qima-color-cherry-600: #C71A21;
$qima-color-cherry-900: #9E141B;
$qima-color-lemon-100: #FFF9E7;
$qima-color-lemon-200: #FFF2CF;
$qima-color-lemon-500: #FFCA0F;
$qima-color-lemon-600: #E5A700;
$qima-color-lemon-900: #BD8A00;
$qima-color-kiwi-100: #EAF6F2;
$qima-color-kiwi-200: #CEEAE1;
$qima-color-kiwi-500: #00A876;
$qima-color-kiwi-600: #008A61;
$qima-color-kiwi-900: #006648;
$qima-color-neutral-carbon: #191919;
$qima-color-neutral-cloud: #F7F7F7;
$qima-color-neutral-mist: #F0F0F0;
$qima-color-neutral-pearl: #DEDEDE;
$qima-color-neutral-seal: #737373;
$qima-color-neutral-smoke: #B3B3B3;
$qima-color-neutral-white: #FFFFFF;
$qima-color-pool-gradient-light: #0DBEE5;
$qima-color-pool-gradient-top: #0BA2C4;
$qima-color-ghost: rgba(255, 255, 255, 40%);
$qima-color-dark-ghost: rgba(255, 255, 255, 20%);

@mixin qima-font-faces($prefix: '../../../assets/') {
  @font-face {
    font-family: 'Inter';
    src:
      url($prefix + 'fonts/inter/regular/Inter-Regular.ttf') format('truetype'),
      url($prefix + 'fonts/inter/regular/Inter-Regular.eot'), /* IE6-IE8 */
      url($prefix + 'fonts/inter/regular/Inter-Regular.woff2') format('truetype'), /* Super Modern Browsers */
      url($prefix + 'fonts/inter/regular/Inter-Regular.woff') format('truetype'); /* Modern Browsers */

    font-weight: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src:
      url($prefix + 'fonts/inter/semi-bold/Inter-SemiBold.eot'),
      url($prefix + 'fonts/inter/semi-bold/Inter-SemiBold.ttf') format('truetype'),
      url($prefix + 'fonts/inter/semi-bold/Inter-SemiBold.woff2') format('truetype'),
      url($prefix + 'fonts/inter/semi-bold/Inter-SemiBold.woff') format('truetype');
    font-weight: 600;
    font-display: swap;
  }
  @font-face {
    font-family: 'Inter';
    src:
      url($prefix + 'fonts/inter/bold/Inter-Bold.eot'),
      url($prefix + 'fonts/inter/bold/Inter-Bold.ttf') format('truetype'),
      url($prefix + 'fonts/inter/bold/Inter-Bold.woff2') format('truetype'),
      url($prefix + 'fonts/inter/bold/Inter-Bold.woff') format('truetype');
    font-weight: bold;
    font-display: swap;
  }
  @font-face {
    font-family: 'Noto Sans SC';
    src:
      url($prefix + 'fonts/noto-sans-sc/regular/NotoSansSC-Regular.eot'),
      url($prefix + 'fonts/noto-sans-sc/regular/NotoSansSC-Regular.otf') format('truetype'),
      url($prefix + 'fonts/noto-sans-sc/regular/NotoSansSC-Regular.woff2') format('truetype'),
      url($prefix + 'fonts/noto-sans-sc/regular/NotoSansSC-Regular.woff') format('truetype');
    font-weight: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Noto Sans SC';
    src:
      url($prefix + 'fonts/noto-sans-sc/medium/NotoSansSC-Medium.otf') format('truetype'),
      url($prefix + 'fonts/noto-sans-sc/medium/NotoSansSC-Medium.woff2') format('truetype'),
      url($prefix + 'fonts/noto-sans-sc/medium/NotoSansSC-Medium.woff') format('truetype');
    font-weight: 500;
    font-display: swap;
  }
  @font-face {
    font-family: 'Noto Sans SC';
    src:
      url($prefix + 'fonts/noto-sans-sc/bold/NotoSansSC-Bold.otf') format('truetype'),
      url($prefix + 'fonts/noto-sans-sc/bold/NotoSansSC-Bold.woff2') format('truetype'),
      url($prefix + 'fonts/noto-sans-sc/bold/NotoSansSC-Bold.woff') format('truetype');
    font-weight: bold;
    font-display: swap;
  }
}

.pdf-body-20 {
  @extend %qp-pdf-body-20;
}

.pdf-body-14 {
  @extend %qp-pdf-body-14;
}

.pdf-body-12 {
  @extend %qp-pdf-body-12;
}

.pdf-body-10 {
  @extend %qp-pdf-body-10;
}

.break-inside--avoid {
  break-inside: avoid;
}

.report-pdf-title {
  @extend %qp-pdf-report-title;
}

.report-pdf-section {
  @extend .break-inside--avoid;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: qp-height-px-to-mm(60);
    border-bottom: 0.5px solid $qima-color-neutral-pearl !important;
    border-radius: 6px 6px 0 0;
    padding: qp-width-px-to-mm(24);
    @extend %qp-pdf-body-14;

    &--success {
      color: $qima-color-neutral-white;
      background-color: $qima-color-kiwi-500;
    }

    &--fail {
      color: $qima-color-neutral-white;
      background-color: $qima-color-cherry-500;
    }

    &__title {
      margin-left: qp-width-px-to-mm(4);

      &__result {
        text-transform: uppercase;
        @include qp-font(qp-width-px-to-mm(16), bold, -0.3px);

        &--success {
          color: $qima-color-kiwi-500;
        }

        &--fail {
          color: $qima-color-cherry-500;
        }
      }
    }
  }

  &__body {
    background-color: $qima-color-neutral-white;
    border-radius: $qima-spacing-base;
    border: solid 0.5px $qima-color-neutral-pearl;
    border-top: none;
    padding: qp-width-px-to-mm(24);
  }
}

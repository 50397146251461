/*
Fluid typography
 */
.picture-initials {
  @extend %qp-typography-picture-initials;
}

.display-l {
  @extend %qp-typography-display-l;
}

.headline-l {
  @extend %qp-typography-headline-l;
}

.headline-m {
  @extend %qp-typography-headline-m;
}

.headline-s {
  @extend %qp-typography-headline-s;
}

.headline-xs {
  @extend %qp-typography-headline-xs;

  &-400 {
    @extend %qp-typography-headline-xs-normal;
  }
}

/*
Fixed typography
 */
.main {
  @extend %qp-typography-main;
}

.body-1 {
  @extend %qp-typography-body-1;

  &--highlighted {
    @extend %qp-typography-body-1-highlighted;
  }
}

.body-2 {
  @extend %qp-typography-body-2;
}

.item {
  @extend %qp-typography-item;

  &--highlighted {
    @extend %qp-typography-item-highlighted;
  }
}

.caption {
  @extend %qp-typography-caption;
}

.heading {
  @extend %qp-typography-heading;
}

.interactive-1 {
  @extend %qp-typography-interactive-1;
}

.interactive-2 {
  @extend %qp-typography-interactive-2;
}

.label {
  @extend %qp-typography-label;
}

.label-bottom-bar {
  @extend %qp-typography-label-bottom-bar;
}

@mixin qp-placeholder($color) {
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $color !important;
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color !important;
    opacity: 1;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color !important;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color !important;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color !important;
  }

  &::placeholder {
    /* Most modern browsers support this now. */
    color: $color !important;
  }
}

// Only use those for paddings and margins
// Basically, the spaces between elements
// Don't use them for height, width, etc
$qima-spacing-min: 2px;
$qima-spacing-base: 4px;
$qima-spacing-xs: 8px;
$qima-spacing-s: 12px;
$qima-spacing-m: 16px;
$qima-spacing-l: 24px;
$qima-spacing-xl: 36px;
$qima-spacing-xxl: 48px;

@import 'projects/library/shared-scss/qp-imports';

.qp-alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &-heading {
    color: inherit;
  }

  &-link {
    font-weight: bold;
  }

  &-dismissible {
    padding-right: 4rem;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      padding: 0.75rem 1.25rem;
      color: inherit;
    }
  }

  &-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}

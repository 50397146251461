// Full page
$full-page-header-height: 72px;
$full-page-footer-height: 60px;
$full-page-max-width: 972px;

// Media queries variables
$mobile-max-width: 767.98px;
$desktop-min-width: 768px;
$laptop-min-width: 1280px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-tablet: 828px;

// UI modal
$modal-header-height: 60px;
$modal-stepper-header-height: 90px;

// Navbar
$subnav-height: 60px;
$navbar-width: 52px;
$navbar-expanded-width: 220px;
$mobile-navbar-height: 60px;

// Padding of main block
$container-fluid-padding: $qima-spacing-m;
$container-fluid-padding-top: $qima-spacing-l;
$container-fluid-margin-top: 89px;

// Content-block width
$content-block-max-width: 816px;
$split-screen-block-width: 375px;

// Button
$qp-button-size-small-height: 28px;
$qp-button-size-medium-height: $qima-spacing-xl;
$qp-button-size-large-height: 52px;
$qp-button-border-radius: 6px;
$main-card-width: 972px;
$qp-fake-text-radius: 5px;
$qp-fake-color: #ebebeb;
$qp-body-line-height: 1.5;
$qp-business-type-card-width: 224px;

// Because browsers have each their own widths
$qp-hypothetical-scrollbar-width: $qima-spacing-m;
$qp-input-large-height: 52px;
$qp-input-medium-height: 44px;
$qp-input-space-with-icon: $qima-spacing-s;

// Same as QP_MENU_ITEM_TEXT_HEIGHT
$qp-menu-item-text-height: $qima-spacing-xxl;

// Same as QP_MENU_ITEM_WITH_IMAGE_HEIGHT
$qp-menu-item-with-image-height: $qima-spacing-xxl;

// Same as QP_MENU_GROUP_HEIGHT
$qp-menu-group-height: 35px;

// Same as QP_MENU_ITEM_INPUT_TEXT_HEIGHT
$qp-menu-item-input-text-height: 76px;

// Same as QP_MENU_ITEM_ICE_CUBE_HEIGHT
$qp-menu-item-ice-cube-height: $qima-spacing-xxl;

// Same as QP_MENU_ITEM_PRODUCT_HEIGHT
$qp-menu-item-product-height: 110px;

// Same as QP_MENU_ITEM_ENTITY_HEIGHT
$qp-menu-item-entity-height: 64px;

// Same as QP_MENU_ITEM_INSPECTOR_HEIGHT
$qp-menu-item-inspector-height: 100px;

// Textarea
$qp-textarea-border-radius: 6px;

// Navbar checklist
$navbar-checklist-width: 60px;

// Booking
$booking-overview-width: 360px;

@mixin qp-col-mixin {
  position: relative;
  width: 100%;
  padding-right: $qima-spacing-m;
  padding-left: $qima-spacing-m;
}

@mixin qp-cols-mixin($count) {
  @include qp-col-mixin;

  flex: calc((100% / 12) * #{$count});
  max-width: calc((100% / 12) * #{$count});
}

@mixin qp-print {
  background-color: $qima-color-neutral-white !important;

  qp-qima-desktop-main-navbar {
    display: none;
  }

  qp-qima-subnav {
    display: none;
  }

  one-subnav {
    display: none;
  }

  one-desktop-main-navbar {
    display: none;
  }

  qp-notification-bar {
    display: none;
  }

  .navbar-container-collapsed {
    display: none;
  }

  .navbar-container-expand {
    display: none;
  }

  .qp-container-fluid {
    width: 100vw !important;
    max-width: 100vw !important;
    padding: 0 !important;
    margin-top: 0 !important;
  }

  .main-content,
  .main-container {
    background-color: $qima-color-neutral-cloud !important;
    display: block !important;
  }

  a:link {
    border-bottom: none !important;
    text-decoration: none !important;
  }
}

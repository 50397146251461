@mixin qima-slow-transition($transition-names...) {
  @extend %qima-reduced-motion;

  transition-property: $transition-names;
  transition-duration: $qima-slow-animation-duration;
  transition-timing-function: $qima-transition-timing-function;
}

@mixin qima-quick-transition($transition-names...) {
  @extend %qima-reduced-motion;

  transition-property: $transition-names;
  transition-duration: $qima-quick-animation-duration;
  transition-timing-function: $qima-transition-timing-function;
}

#notifuse-dropdown {
  .notifuse-dropdown-container {
    &__links {
      display: none;
    }

    &__header {
      .notifuse-dropdown-container__links {
        display: block;
      }
    }

    &__item-cover {
      border-radius: $qima-spacing-base;
    }

    &__item-description {
      color: $qima-color-neutral-carbon;

      .content {
        margin-right: $qima-spacing-s;
      }

      .badge {
        @include qp-font(10px, bold);

        display: inline-block;
        color: $qima-color-neutral-white;
        line-height: 1.2;
        padding: 3px $qima-spacing-xs;
        border-radius: $qima-spacing-xs;
        margin-bottom: $qima-spacing-base;
      }

      .badge-primary {
        background-color: $qima-color-pool-500;
      }

      .badge-secondary {
        background-color: $qima-color-neutral-seal;
      }

      .badge-success {
        background-color: $qima-color-kiwi-500;
      }

      .badge-danger {
        background-color: $qima-color-cherry-500;
      }

      .badge-warning {
        background-color: $qima-color-orange-500;
      }
    }

    &__item-time {
      color: $qima-color-neutral-seal;
    }
  }
}

@media screen and (max-width: $mobile-max-width) {
  #notifuse-dropdown {
    .notifuse-dropdown-container {
      position: fixed;
      margin: auto;
      inset: auto 0;
      z-index: 21;
      width: calc(100vw - 32px);
      overflow: hidden;
      max-height: 90%;

      &__items_list {
        overflow: scroll;
      }
    }
  }
}

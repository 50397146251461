@import 'projects/library/shared-scss/qp-imports';

.qp-justify-content-center {
  justify-content: center;
}

.qp-justify-content-end {
  justify-content: flex-end;
}

.qp-text-danger {
  color: $qima-color-cherry-600;
}

.qp-text-center {
  text-align: center;
}

.qp-font-weight-bold {
  font-weight: bold;
}

/* stylelint-disable scss/no-duplicate-dollar-variables */
// Casts a string into a number
// @param {String | Number} $value - Value to be parsed
// @return {Number}
@function to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  }

  $first-character: str-slice($value, 1, 1);
  $result: 0;
  $digits: 0;
  $minus: ($first-character == '-');
  $numbers: (
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
  );

  // Remove +/- sign if present at first character
  @if $first-character == '+' or $first-character == '-' {
    $value: str-slice($value, 2);
  }
  @for $i from 1 through str-length($value) {
    $character: str-slice($value, $i, $i);
    @if not(index(map-keys($numbers), $character) or $character == '.') {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i));
    }
    @if $character == '.' {
      $digits: 1;
    } @else if $digits == 0 {
      $result: $result * 10 + map-get($numbers, $character);
    } @else {
      $digits: $digits * 10;
      $result: $result + map-get($numbers, $character) / $digits;
    }
  }
  @return if($minus, -$result, $result);
}

// Add `$unit` to `$value`
// @param {Number} $value - Value to add unit to
// @param {String} $unit - String representation of the unit
// @return {Number} - `$value` expressed in `$unit`
@function to-length($value, $unit) {
  $units: (
    'px': 1px,
    'cm': 1cm,
    'mm': 1mm,
    '%': 1%,
    'ch': 1ch,
    'pc': 1pc,
    'in': 1in,
    'em': 1em,
    'rem': 1rem,
    'pt': 1pt,
    'ex': 1ex,
    'vw': 1vw,
    'vh': 1vh,
    'vmin': 1vmin,
    'vmax': 1vmax,
  );
  @return $value * map-get($units, $unit);
}

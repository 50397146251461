%qp-pdf-report-title {
  margin-bottom: qp-width-px-to-mm(15);
  font-weight: normal;
  color: $qima-color-neutral-carbon;
  display: flex;
  align-items: center;
  padding: qp-width-px-to-mm(12) 0;
  border-top: solid $pdf-border-size $qima-color-neutral-pearl;
  border-bottom: dashed $pdf-border-size $qima-color-neutral-pearl;
  @extend %qp-pdf-body-14;
  @media screen and (max-width: $mobile-max-width) {
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .icon,
  em {
    color: $qima-color-neutral-carbon;
    margin-right: qp-width-px-to-mm(8);
    transform: none;
    font-size: qp-width-px-to-mm(12);
  }

  .separator {
    color: $qima-color-neutral-seal;
    font-weight: normal;
    margin: 0 qp-width-px-to-mm(5);
  }

  .subtitle {
    color: $qima-color-neutral-seal;
  }
}
